export const SOCIAL_NETWORKS_GEO = {
    mo: {
        instagram: 'https://www.instagram.com/indrive.ma/',
        facebook: 'https://www.facebook.com/indrive.morocco',
        twitter: 'https://twitter.com/inDrive',
        policy: 'https://indrive.com/mo/privacy_policy',
        offer: 'https://indrive.com/mo/offer',
    },
    dz: {
        instagram: 'https://www.instagram.com/indrive.dz/',
        facebook: 'https://www.facebook.com/indrive.algeria',
        twitter: 'https://twitter.com/inDrive',
    },
    tn: {
        instagram: 'https://www.instagram.com/indrive.tn/',
        facebook: 'https://www.facebook.com/indrive.tunisia',
        twitter: 'https://twitter.com/inDrive',
    },
    br: {
        instagram: 'https://www.instagram.com/indrive.br/',
        facebook: 'https://www.facebook.com/indrive.brasil',
        twitter: 'https://twitter.com/inDriveBrasil',
    },
    mx: {
        instagram: 'https://www.instagram.com/indrive.mx/',
        facebook: 'https://www.facebook.com/indrive.mexico',
        twitter: 'https://twitter.com/inDrive_Latam?utm_source=socialicons&utm_content=indriver.com',
    },
    co: {
        instagram: 'https://www.instagram.com/indrive.co/',
        facebook: 'https://www.facebook.com/indrive.colombia',
        twitter: 'https://twitter.com/inDrive_Latam?utm_source=socialicons&utm_content=indriver.com',
    },
    za: {
        instagram: 'https://www.instagram.com/indrive.za/',
        facebook: 'https://www.facebook.com/indrive.southafrica',
        twitter: 'https://twitter.com/inDrive',
    },
    jm: {
        instagram: 'https://www.instagram.com/indrive.jm/',
        facebook: 'https://www.facebook.com/indrive.jamaica',
        twitter: 'https://twitter.com/inDrive',
    },
    eg: {
        instagram: 'https://www.instagram.com/indrive.eg/',
        facebook: 'https://www.facebook.com/indrive.egypt',
        twitter: 'https://twitter.com/inDrive',
    },
    in: {
        instagram: 'https://www.instagram.com/indrive.in/',
        facebook: 'https://www.facebook.com/indrive.india',
        twitter: 'https://twitter.com/inDrive',
    },
    np: {
        instagram: 'https://www.instagram.com/indrive.np/',
        facebook: 'https://www.facebook.com/indrive.nepal',
        twitter: 'https://twitter.com/inDrive',
    },
    pk: {
        instagram: 'https://www.instagram.com/indrive.pk/',
        facebook: 'https://www.facebook.com/indrive.pakistan',
        twitter: 'https://twitter.com/inDrive',
    },
    my: {
        instagram: 'https://www.instagram.com/indrive.my/',
        facebook: 'https://www.facebook.com/indrive.malaysia',
        twitter: 'https://twitter.com/inDrive',
    },
    id: {
        instagram: 'https://www.instagram.com/indrive.id/',
        facebook: 'https://www.facebook.com/indrive.indonesia',
        twitter: 'https://twitter.com/inDrive',
    },
    th: {
        instagram: 'https://www.instagram.com/indrive.th/',
        facebook: 'https://www.facebook.com/indrive.thailand',
        twitter: 'https://twitter.com/inDrive',
    },
    vn: {
        instagram: 'https://www.instagram.com/indrive.vn/',
        facebook: 'https://www.facebook.com/indrive.vietnam',
        twitter: 'https://twitter.com/inDrive',
    },
    au: {
        instagram: 'https://www.instagram.com/indrive.au/',
        facebook: 'https://www.facebook.com/indrive.australia',
        twitter: 'https://twitter.com/inDrive',
    },
    latam: {
        instagram: 'https://www.instagram.com/indrive.latam/',
        facebook: 'https://www.facebook.com/indrive.latam',
        twitter: 'https://twitter.com/inDriveLatam',
    },
    am: {
        instagram: 'https://www.instagram.com/indrive.am/',
        facebook: 'https://www.facebook.com/indrive.armenia',
        twitter: '',
    },
    kz: {
        instagram: 'https://www.instagram.com/indrive.kz/',
        facebook: 'https://www.facebook.com/indrive.kazakhstan',
        twitter: 'https://twitter.com/inDrive',
    },
    ru: {
        instagram: 'https://www.instagram.com/indrive.ru/',
        facebook: 'https://www.facebook.com/indrive.russia',
        twitter: 'https://twitter.com/inDriveRussia',
        policy: 'https://indrive.com/ru/privacy_policy',
        offer: 'https://indrive.com/ru/offer',
    },
    uz: {
        instagram: 'https://www.instagram.com/indrive.uz/',
        facebook: 'https://www.facebook.com/indrive.uzbekistan',
        twitter: 'https://twitter.com/inDrive',
    },
    ar: {
        instagram: 'https://www.instagram.com/indrive.ar/',
        facebook: 'https://www.facebook.com/indrive.argentina',
        twitter: 'https://twitter.com/inDrive',
    },
    cl: {
        instagram: 'https://www.instagram.com/indrive.cl/',
        facebook: 'https://www.facebook.com/indrive.chile',
        twitter: 'https://twitter.com/inDrive',
    },
    pe: {
        instagram: 'https://www.instagram.com/indrive.pe/',
        facebook: 'https://www.facebook.com/indrive.peru',
        twitter: 'https://twitter.com/inDrive',
    },
    tr: {
        instagram: 'https://www.instagram.com/indrive.tr/',
        facebook: 'https://www.facebook.com/indrive.turkey',
        twitter: 'https://twitter.com/inDrive',
    },
    ph: {
        instagram: 'https://www.instagram.com/indrive.ph/',
        facebook: 'https://www.facebook.com/indrive.philippines',
        twitter: 'https://twitter.com/inDrive',
    },
    ke: {
        instagram: 'https://www.instagram.com/indrive.ke/',
        facebook: 'https://www.facebook.com/indrive.kenya',
        twitter: 'https://twitter.com/inDrive',
    },
    na: {
        instagram: 'https://www.instagram.com/indrive.na/',
        facebook: 'https://www.facebook.com/indrive.namibia',
        twitter: 'https://twitter.com/inDrive',
    },
    ng: {
        instagram: 'https://www.instagram.com/indrive.ng/',
        facebook: 'https://www.facebook.com/indrive.nigeria',
        twitter: 'https://twitter.com/inDrive',
    },
    tz: {
        instagram: 'https://www.instagram.com/indrive.tz/',
        facebook: 'https://www.facebook.com/indrive.tanzania',
        twitter: 'https://twitter.com/inDrive',
    },
    bw: {
        instagram: 'https://www.instagram.com/indrive.bw/',
        facebook: 'https://www.facebook.com/indrive.botswana',
        twitter: 'https://twitter.com/inDrive',
    },
    gh: {
        instagram: 'https://www.instagram.com/indrive.gh/',
        facebook: 'https://www.facebook.com/indrive.ghana',
        twitter: 'https://twitter.com/inDrive',
    },
    ge: {
        instagram: 'https://www.instagram.com/indrive.ge/',
        facebook: 'https://www.facebook.com/indrive.georgia',
        twitter: 'https://twitter.com/inDrive',
    },
    lb: {
        instagram: 'https://www.instagram.com/indrive.lb/',
        facebook: 'https://www.facebook.com/indrive.lebanon',
        twitter: 'https://twitter.com/inDrive',
    },
    bd: {
        instagram: 'https://www.instagram.com/indrive.bd/',
        facebook: 'https://www.facebook.com/indrive.bangladesh',
        twitter: 'https://twitter.com/inDrive',
    },
    default: {
        instagram: 'https://www.instagram.com/indrive/',
        facebook: 'https://www.facebook.com/indrive/',
        twitter: 'https://twitter.com/inDrive',
        policy: 'https://indrive.com/en/privacy_policy',
        offer: 'https://indrive.com/en/offer',
    },
};

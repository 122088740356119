import { Heading as HeadingNova, IHeadingProps } from '@indriver/nova';
import { FC } from 'react';
import { ITranslate } from 'entities/ITranslate';
import { useTranslation } from 'next-i18next';
import { highlightText } from 'shared/lib/highlight-text';
import { Heading, HeadingBottomLine } from './landing-heading.style';

export interface IExtendedHeadingProps {
    text: ITranslate | string;
    isTitle?: boolean;
}

const LandingHeading: FC<IHeadingProps & IExtendedHeadingProps> = ({ text, isTitle = false, ...props }) => {
    const { t } = useTranslation();
    return (
        <HeadingNova
            {...props}
            className={isTitle ? HeadingBottomLine : Heading}
            dangerouslySetInnerHTML={{
                __html: isTitle ? highlightText(t(text)) : t(text),
            }}
        />
    );
};

export default LandingHeading;

import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'next-i18next';

const items = [
    {
        title: 'bids.searching_drivers_title',
        text: 'bids.offers_will_appear_new',
        duration: 5,
    },
    {
        title: 'bids.sending_your_request',
        text: 'bids.offers_will_appear_new',
        duration: 15,
    },
    {
        title: 'bids.offering_your_terms',
        text: 'bids.offers_will_appear_new',
        duration: 15,
    },
    {
        title: 'bids.waiting_for_drivers',
        text: 'bids.offers_will_appear_new',
        duration: 15,
    },
    {
        title: 'bids.getting_offers',
        text: 'bids.offers_will_appear_new',
        duration: 15,
    },
];

const AnimatedText = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { t } = useTranslation();
    useEffect(() => {
        if (currentIndex === items.length - 1) {
            const timer = setTimeout(() => {
                const updatedItems = [...items];
                updatedItems[0].duration = 10;
                setCurrentIndex(0);
            }, items[currentIndex].duration * 1000);

            return () => clearTimeout(timer);
        }
        const timer = setTimeout(() => {
            setCurrentIndex(currentIndex + 1);
        }, items[currentIndex].duration * 1000);
        return () => clearTimeout(timer);
    }, [currentIndex]);

    return (
        <>
            <AnimatePresence exitBeforeEnter>
                {items.map(
                    (item, index) =>
                        index === currentIndex && (
                            <div key={item.title}>
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.5 }}>
                                    <h4>{t(item.title)}</h4>
                                </motion.div>
                                <p>{t(item.text)}</p>
                            </div>
                        ),
                )}
            </AnimatePresence>
        </>
    );
};

export default AnimatedText;

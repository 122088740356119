const IndriveLogo = () => {
    return (
        <svg width={56} height={56} fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M0 28c0-7.774 0-11.661 1.085-14.789A19.647 19.647 0 0 1 13.212 1.085C16.338 0 20.226 0 28 0c7.775 0 11.661 0 14.788 1.085a19.646 19.646 0 0 1 12.128 12.127C56 16.338 56 20.226 56 28c0 7.775 0 11.661-1.084 14.788a19.648 19.648 0 0 1-12.127 12.128C39.66 56 35.774 56 28 56c-7.774 0-11.661 0-14.789-1.084A19.649 19.649 0 0 1 1.085 42.789C0 39.66 0 35.774 0 28Z'
                fill='#A7E92F'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15.502 24.12c-.637 0-1.06.423-1.06 1.06v16.57c0 .638.423 1.061 1.06 1.061h4.471c.637 0 1.06-.423 1.06-1.06V25.18c0-.637-.423-1.06-1.06-1.06h-4.471ZM15.502 13.17c-.637 0-1.06.423-1.06 1.06v4.293c0 .639.423 1.06 1.06 1.06h4.471c.637 0 1.06-.421 1.06-1.06V14.23c0-.636-.423-1.06-1.06-1.06h-4.471ZM30.719 36.41H27.15c-.637 0-1.06.423-1.06 1.06v4.28c0 .636.423 1.056 1.06 1.06l3.568.023c20.33 0 20.33-29.666 0-29.666l-3.568.023c-.637.005-1.06.423-1.06 1.06v4.28c0 .637.423 1.06 1.06 1.06h3.568c11.5 0 11.5 16.82 0 16.82Z'
                fill='#000'
            />
        </svg>
    );
};

export default IndriveLogo;

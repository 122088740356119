import * as crypto from 'crypto';

export const hashPhoneNumber = (phoneNumber: string): string => {
    // Привести номер телефона к формату E.164
    const formattedPhoneNumber = `+${phoneNumber.replace(/\D/g, '')}`;

    // Хеширование номера телефона с использованием SHA-256
    const hashedPhoneNumber = crypto.createHash('sha256').update(formattedPhoneNumber);
    return hashedPhoneNumber.digest('hex');
};

import { LoaderWrapper } from 'shared/ui/loader/loader.style';
import { Loader as LoaderNova } from '@indriver/nova';

const Loader = () => {
    return (
        <LoaderWrapper>
            <div>
                <LoaderNova size='xl' />
            </div>
        </LoaderWrapper>
    );
};

export default Loader;

import { Input, Loader } from '@indriver/nova';
import React, { memo, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import useDebounce from 'shared/hooks/useDebounce';
import { IAutocompleteCity } from 'common/api';
import RoundClose from 'shared/ui/round-close';
import { variables } from '@indriver/yrel';
import { usePopper } from 'react-popper';
import * as Sentry from '@sentry/nextjs';
import { Dropdown, DropdownItem, InputSearchWrapper, StyledInputSearch } from './input-search.styles';

export interface ISearchItem {
    readonly value: string | number;
    readonly label: string;
    readonly data: IAutocompleteCity;
}

interface IInputSearchProps {
    loadData: (value: string) => Promise<ISearchItem[]>;
    onSelect: (item?: ISearchItem) => void;
    selectedItem?: ISearchItem | null;
    placeholder?: string;
    startSearching?: number;
    invalid?: boolean;
    changeBtn?: ReactElement | null | '';
    onClickWrapper?: () => void;
    dir?: 'ltr' | 'rtl';
}

const InputSearch = memo<IInputSearchProps>(
    ({
        placeholder = '',
        invalid = false,
        loadData,
        onSelect,
        selectedItem,
        changeBtn,
        startSearching = 2,
        onClickWrapper,
        dir = 'ltr',
    }) => {
        const [items, setItems] = useState<ISearchItem[]>([]);
        const [search, setSearch] = useState('');
        const [isLoading, setIsLoading] = useState(true);
        // const [isOpen, setIsOpen] = useState(false);
        const [selectedValue, setSelectedValue] = useState<ISearchItem | undefined | null>(null);
        const [focus, setFocus] = useState(false);
        const debouncedSearch = useDebounce<string>(search, 600);
        const debouncedFocus = useDebounce(focus, 300);

        const dropdownRef = useRef<HTMLDivElement>(null);
        const inputRef = useRef<HTMLDivElement>(null);
        const { styles, attributes } = usePopper(inputRef.current, dropdownRef.current, {
            placement: 'bottom-start',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 0],
                    },
                },
                {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                        padding: 56,
                    },
                },
                {
                    name: 'flip',
                    enabled: true,
                    options: {
                        fallbackPlacements: ['top-start', 'bottom-start'],
                    },
                },
            ],
        });

        const fetchCities = useCallback(
            async (value: string): Promise<boolean> => {
                try {
                    setIsLoading(true);
                    const result = await loadData(value);
                    setItems(result);
                    return true;
                } catch (error) {
                    Sentry.captureException(error);
                    return false;
                } finally {
                    setIsLoading(false);
                }
            },
            [loadData],
        );

        useEffect(() => {
            if (debouncedSearch && debouncedSearch.length >= startSearching && !selectedItem?.value) {
                fetchCities(debouncedSearch)
                    .then((r) => r)
                    .catch((e) => Sentry.captureException(e))
                    .finally(() => {
                        setIsLoading(false);
                    });
            } else {
                setItems([]);
                setIsLoading(false);
            }
        }, [debouncedSearch, fetchCities, selectedItem?.value, startSearching]);

        useEffect(() => {
            setSelectedValue(selectedItem?.value ? selectedItem : null);
        }, [selectedItem?.value]);

        useEffect(() => {
            if (selectedItem?.value && selectedItem?.value !== selectedValue?.value) {
                setSearch(selectedItem.label);
            } else if (typeof selectedValue === 'undefined') {
                setSearch(search);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
            } else if (selectedValue === null && selectedItem === '' && search.length > 0) {
                setSearch('');
                setSelectedValue(null);
                // console.log({ name }, 'cond3');
            }
        }, [selectedItem?.value, selectedValue?.value]);

        const handleSelect = useCallback(
            (item?: ISearchItem) => {
                onSelect(item);
                setSelectedValue(item || undefined);
            },
            [onSelect],
        );

        useEffect(() => {
            if (!focus && !selectedItem?.value && !selectedValue?.value) {
                setSearch('');
            }
        }, [focus]);

        // useOnClickOutside(dropdownRef, () => setIsOpen(false));

        return (
            <InputSearchWrapper value={search} ref={inputRef} onClick={onClickWrapper}>
                {/* {search && <span className='placeholder'>{placeholder}</span>} */}
                <Input
                    dir={dir}
                    design='floating'
                    className={StyledInputSearch}
                    autoComplete='off'
                    placeholder={placeholder}
                    value={selectedItem?.label ?? search}
                    invalid={invalid}
                    postfix={
                        isLoading ? (
                            <Loader size='s' design='primary' />
                        ) : debouncedFocus && search.length > 0 ? (
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                            <div
                                style={{ cursor: 'pointer' }}
                                tabIndex={-1}
                                role='button'
                                onClick={() => {
                                    handleSelect();
                                    setSelectedValue(null);
                                    setSearch('');
                                    setItems([]);
                                }}>
                                <RoundClose size={20} color={variables['text-and-icon-secondary']} />
                            </div>
                        ) : (
                            changeBtn
                        )
                    }
                    onFocus={() => {
                        setFocus(true);
                        // setIsOpen(true);
                    }}
                    onBlur={() => {
                        setFocus(false);
                        // setIsOpen(false);
                    }}
                    onInput={(event) => {
                        handleSelect(undefined);
                        setSearch(event.currentTarget.value);
                    }}
                    onChange={(event, data) => {
                        handleSelect(undefined);
                        setSearch(data.value);
                    }}
                />

                <Dropdown visible={items?.length > 0} ref={dropdownRef} style={styles.popper} {...attributes.popper}>
                    {items.map((city) => (
                        <DropdownItem
                            key={city.value}
                            title={city.label}
                            onClick={() => {
                                // setIsOpen(false);
                                handleSelect(city);
                                setSearch(city.label);
                            }}>
                            <div>{city.label}</div>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            <span>{city?.data?.sub_name}</span>
                        </DropdownItem>
                    ))}
                </Dropdown>
            </InputSearchWrapper>
        );
    },
);

export default InputSearch;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { Link } from '@indriver/nova';
import { FC, MouseEvent } from 'react';
import { useCountdown } from 'shared/hooks/useCountdown';
import { TimerStyled } from './timer.styles';

interface IProps {
    seconds?: number;
    onClick: (event: MouseEvent<HTMLAnchorElement>) => Promise<void>;
}

export const Timer: FC<IProps> = ({ seconds, onClick }) => {
    const coundown = useCountdown(seconds ?? 0);

    if (!coundown || coundown <= 0) {
        return (
            <TimerStyled>
                <Link href='#' onClick={onClick}>
                    Получить код повторно
                </Link>
            </TimerStyled>
        );
    }

    return (
        <TimerStyled>
            <span>Получить код повторно через {coundown} сек</span>
        </TimerStyled>
    );
};

const AppGallery = () => {
    return (
        <svg width='134' height='40' viewBox='0 0 134 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_2465_55584)'>
                <path
                    d='M124.592 0H8.99473C8.6934 0 8.39206 0 8.08806 0C7.42506 0.00964053 6.76369 0.0684687 6.1094 0.176C5.45933 0.286098 4.82987 0.49472 4.24273 0.794667C3.65214 1.0966 3.11231 1.48888 2.64273 1.95733C2.17323 2.42602 1.78083 2.96603 1.48006 3.55733C1.17647 4.15282 0.966027 4.79136 0.856063 5.45067C0.745543 6.10368 0.686694 6.7644 0.680063 7.42667C0.664062 7.73333 0.664062 8.032 0.664062 8.336V31.6667C0.664062 31.9733 0.664062 32.272 0.680063 32.5787C0.686925 33.2409 0.745773 33.9016 0.856063 34.5547C0.965075 35.2046 1.17281 35.8341 1.47206 36.4213C2.07698 37.6157 3.04393 38.5882 4.23473 39.2C4.82212 39.5005 5.45139 39.7108 6.1014 39.824C6.75575 39.9307 7.41713 39.9886 8.08006 39.9973C8.38406 39.9973 8.6854 39.9973 8.98673 39.9973H125.653C125.955 39.9973 126.261 39.9973 126.563 39.9973C127.224 39.9892 127.884 39.9312 128.536 39.824C129.192 39.7104 129.827 39.5001 130.421 39.2C131.014 38.9012 131.554 38.5086 132.021 38.0373C132.493 37.5694 132.888 37.0293 133.189 36.4373C133.486 35.8494 133.692 35.2201 133.8 34.5707C133.91 33.9175 133.972 33.257 133.984 32.5947C133.984 32.288 133.984 31.9893 133.984 31.6827C133.984 31.3253 133.984 30.968 133.984 30.616V9.41333C133.984 9.05067 133.984 8.69333 133.984 8.34667C133.984 8.04267 133.984 7.73867 133.984 7.43733C133.972 6.775 133.911 6.11449 133.8 5.46133C133.691 4.81217 133.485 4.183 133.189 3.59467C132.585 2.39685 131.62 1.41924 130.429 0.8C129.835 0.50313 129.2 0.297275 128.544 0.189333C127.892 0.0812174 127.232 0.0223844 126.571 0.0133333C126.269 0.0133333 125.963 0.0133333 125.661 0.0133333L124.592 0Z'
                    fill='#B6B6B6'
                />
                <path
                    d='M9.00008 39.1361C8.69874 39.1361 8.40541 39.1361 8.10674 39.1361C7.48854 39.1281 6.87177 39.0746 6.26141 38.9761C5.69328 38.8796 5.14248 38.6999 4.62674 38.4428C3.6021 37.9176 2.76753 37.0849 2.24008 36.0615C1.9805 35.5458 1.80068 34.9937 1.70674 34.4241C1.60478 33.8124 1.55039 33.1936 1.54408 32.5735C1.54408 32.3654 1.52808 31.6721 1.52808 31.6721V8.33611C1.52808 8.33611 1.52808 7.65344 1.54408 7.45344C1.54869 6.83242 1.60219 6.21273 1.70408 5.60011C1.7968 5.0285 1.97667 4.4745 2.23741 3.95744C2.49661 3.44865 2.83607 2.98494 3.24274 2.58411C3.6489 2.17752 4.11581 1.83656 4.62674 1.57344C5.14133 1.31583 5.69133 1.13609 6.25874 1.04011C6.87067 0.939475 7.48931 0.885099 8.10941 0.877441H9.00008H125.656H126.557C127.172 0.882701 127.786 0.936199 128.392 1.03744C128.965 1.13145 129.521 1.31125 130.04 1.57077C131.067 2.10053 131.901 2.94024 132.424 3.97078C132.683 4.4839 132.863 5.03322 132.957 5.60011C133.06 6.21732 133.117 6.84122 133.128 7.46678C133.128 7.74678 133.128 8.04811 133.128 8.34678C133.128 8.71744 133.128 9.06944 133.128 9.41344V30.5868C133.128 30.9468 133.128 31.2961 133.128 31.6534C133.128 32.0108 133.128 32.2695 133.128 32.5735C133.118 33.1866 133.062 33.7981 132.96 34.4028C132.869 34.9765 132.689 35.5324 132.427 36.0508C131.897 37.0704 131.064 37.9006 130.043 38.4268C129.524 38.6866 128.97 38.8665 128.397 38.9601C127.787 39.0592 127.17 39.1127 126.552 39.1201C126.264 39.1201 125.96 39.1201 125.667 39.1201H9.00008V39.1361Z'
                    fill='#1B1B1C'
                />
                <path
                    d='M50.1548 27.0026H45.4642L44.3255 30.1333H42.2642L46.8508 18.4373H48.7975L53.4882 30.1333H51.3308L50.1548 27.0026ZM46.0615 25.4026H49.5548L48.3042 22.0533C48.1335 21.5919 47.9628 21.0799 47.7975 20.5199C47.6455 21.0159 47.4802 21.5199 47.3042 22.0373L46.0615 25.4026Z'
                    fill='white'
                />
                <path
                    d='M54.3174 23.5547C54.3174 22.9093 54.2961 22.1982 54.2534 21.4213H56.1201C56.1902 21.787 56.2383 22.1566 56.2641 22.528C56.5907 22.1366 56.9981 21.8205 57.4584 21.6014C57.9187 21.3823 58.421 21.2654 58.9308 21.2587C59.5284 21.2492 60.1154 21.4182 60.6165 21.7441C61.1177 22.07 61.5103 22.5379 61.7441 23.088C62.0721 23.8443 62.2279 24.6641 62.2001 25.488C62.2204 26.3864 62.0381 27.2777 61.6668 28.096C61.3561 28.7751 60.8489 29.3455 60.2108 29.7333C59.5541 30.1128 58.8063 30.3055 58.0481 30.2907C57.4514 30.2903 56.8591 30.1893 56.2961 29.992V33.6L54.3014 33.7707L54.3174 23.5547ZM59.5601 28C59.9654 27.4667 60.1708 26.6667 60.1708 25.6C60.1708 24.6276 60.0019 23.9165 59.6641 23.4667C59.5115 23.2576 59.3104 23.0888 59.0782 22.9745C58.846 22.8602 58.5895 22.804 58.3308 22.8107C57.9581 22.8057 57.5906 22.8976 57.2641 23.0773C56.9173 23.2738 56.6018 23.521 56.3281 23.8107V28.4773C56.5491 28.5815 56.7819 28.6585 57.0214 28.7067C57.2791 28.7624 57.5418 28.791 57.8054 28.792C58.1402 28.8121 58.4748 28.7504 58.7805 28.6125C59.0861 28.4745 59.3537 28.2643 59.5601 28Z'
                    fill='white'
                />
                <path
                    d='M63.4134 23.5547C63.4134 22.9093 63.392 22.1982 63.3494 21.4213H65.216C65.2884 21.7867 65.3373 22.1564 65.3627 22.528C66.1396 21.6818 67.0285 21.2587 68.0294 21.2587C68.627 21.2492 69.214 21.4182 69.7151 21.7441C70.2163 22.07 70.6089 22.5379 70.8427 23.088C71.1707 23.8443 71.3265 24.6641 71.2987 25.488C71.3232 26.3853 71.1454 27.2767 70.7787 28.096C70.4673 28.7755 69.9592 29.3459 69.32 29.7333C68.6636 30.1131 67.9156 30.3059 67.1574 30.2907C66.5607 30.2903 65.9684 30.1893 65.4054 29.992V33.6L63.4107 33.7707L63.4134 23.5547ZM68.664 28C69.0694 27.4542 69.272 26.6542 69.272 25.6C69.272 24.6276 69.104 23.9165 68.768 23.4667C68.6154 23.2576 68.4144 23.0888 68.1821 22.9745C67.9499 22.8602 67.6934 22.804 67.4347 22.8107C67.0621 22.8062 66.6947 22.898 66.368 23.0773C66.0212 23.2738 65.7057 23.521 65.432 23.8107V28.4773C65.6533 28.5808 65.886 28.6577 66.1254 28.7067C66.383 28.7624 66.6458 28.791 66.9094 28.792C67.2441 28.8116 67.5785 28.7498 67.8841 28.6118C68.1897 28.4739 68.4573 28.264 68.664 28Z'
                    fill='white'
                />
                <path
                    d='M74.9654 29.5814C74.1132 29.114 73.4211 28.4014 72.9787 27.536C72.4952 26.5582 72.2572 25.4772 72.2854 24.3867C72.2508 23.22 72.5269 22.065 73.0854 21.04C73.5897 20.1506 74.3471 19.4312 75.2614 18.9734C76.2222 18.5039 77.2801 18.2673 78.3494 18.2827C78.9978 18.2799 79.6442 18.3551 80.2747 18.5067C80.8644 18.6455 81.4368 18.8494 81.9814 19.1147L81.464 20.648C80.5208 20.1722 79.483 19.9134 78.4267 19.8907C77.6811 19.8783 76.9454 20.0622 76.2934 20.424C75.6747 20.7792 75.1775 21.3126 74.8667 21.9547C74.5093 22.7162 74.3357 23.5511 74.36 24.392C74.3467 25.1497 74.4789 25.9028 74.7494 26.6107C74.9949 27.2372 75.4268 27.7734 75.9867 28.1467C76.6246 28.5458 77.3682 28.7429 78.12 28.712C78.5096 28.7111 78.8985 28.6781 79.2827 28.6134C79.6545 28.5543 80.0197 28.4596 80.3734 28.3307V25.952H77.6694V24.4027H82.312V29.3334C81.6385 29.6473 80.9331 29.8878 80.208 30.0507C79.4891 30.2163 78.7538 30.3004 78.016 30.3014C76.9542 30.3268 75.9037 30.0789 74.9654 29.5814Z'
                    fill='white'
                />
                <path
                    d='M89.3974 30.1333C89.322 29.7525 89.2757 29.3665 89.2588 28.9786C88.9256 29.3662 88.5186 29.6835 88.0614 29.912C87.5453 30.1734 86.9733 30.3052 86.3948 30.296C85.9214 30.3019 85.4528 30.2008 85.0241 30C84.6163 29.8087 84.2753 29.4993 84.0454 29.112C83.7907 28.673 83.6641 28.1713 83.6801 27.664C83.6651 27.2792 83.74 26.8962 83.8988 26.5453C84.0575 26.1945 84.2958 25.8854 84.5948 25.6426C85.2028 25.1378 86.217 24.8862 87.6374 24.888C88.1665 24.8877 88.695 24.9252 89.2188 25V24.8293C89.2188 24.0613 89.0552 23.5191 88.7281 23.2026C88.3371 22.8662 87.8295 22.6967 87.3148 22.7306C86.8537 22.7363 86.3951 22.799 85.9494 22.9173C85.5079 23.0238 85.0775 23.172 84.6641 23.36L84.3494 21.9093C84.8017 21.7163 85.2714 21.5672 85.7521 21.464C86.3567 21.3273 86.9749 21.2593 87.5948 21.2613C88.2516 21.2434 88.9051 21.3604 89.5148 21.6053C90.051 21.8424 90.4877 22.2593 90.7494 22.784C91.0767 23.4865 91.2295 24.2577 91.1948 25.032V28.4986C91.1948 28.8542 91.2161 29.4 91.2588 30.136L89.3974 30.1333ZM88.2028 28.5333C88.5872 28.3547 88.9324 28.1018 89.2188 27.7893V26.1893C88.7761 26.137 88.3311 26.1076 87.8854 26.1013C87.0623 26.1013 86.4925 26.2346 86.1761 26.5013C86.0217 26.6317 85.899 26.7955 85.8173 26.9803C85.7355 27.1651 85.6968 27.366 85.7041 27.568C85.6897 27.7425 85.7129 27.918 85.7723 28.0827C85.8316 28.2474 85.9257 28.3975 86.0481 28.5226C86.308 28.7419 86.6419 28.8535 86.9814 28.8346C87.4061 28.8282 87.8238 28.7252 88.2028 28.5333Z'
                    fill='white'
                />
                <path
                    d='M92.7361 28.0427V17.7494L94.7201 17.5894V27.688C94.6919 27.9684 94.7586 28.2501 94.9094 28.488C94.9856 28.57 95.0795 28.6335 95.184 28.6737C95.2884 28.7139 95.4006 28.7297 95.5121 28.72C95.693 28.7122 95.8721 28.6817 96.0454 28.6294L96.2801 30.0587C96.0568 30.143 95.8253 30.2038 95.5894 30.24C95.3349 30.2842 95.0771 30.3074 94.8188 30.3094C93.4303 30.3005 92.7361 29.5449 92.7361 28.0427Z'
                    fill='white'
                />
                <path
                    d='M97.6987 28.0427V17.7494L99.6854 17.5894V27.688C99.6572 27.9684 99.7239 28.2501 99.8747 28.488C99.9509 28.57 100.045 28.6335 100.149 28.6737C100.254 28.7139 100.366 28.7297 100.477 28.72C100.658 28.7122 100.837 28.6817 101.011 28.6294L101.243 30.0587C101.02 30.143 100.79 30.2038 100.555 30.24C100.299 30.2843 100.041 30.3075 99.7814 30.3094C98.3947 30.3005 97.7005 29.5449 97.6987 28.0427Z'
                    fill='white'
                />
                <path
                    d='M109.403 26.032H103.704C103.795 27.8578 104.664 28.7707 106.312 28.7707C106.735 28.7685 107.156 28.7184 107.568 28.6214C107.989 28.5235 108.4 28.3897 108.797 28.2214L109.235 29.5867C108.208 30.0705 107.084 30.3132 105.949 30.296C105.136 30.3195 104.331 30.136 103.608 29.7627C102.982 29.4244 102.48 28.8958 102.173 28.2534C101.835 27.5091 101.671 26.6974 101.693 25.88C101.671 25.0236 101.846 24.1734 102.203 23.3947C102.507 22.7339 103 22.1774 103.619 21.7947C104.253 21.4211 104.979 21.2309 105.715 21.2454C106.445 21.2195 107.166 21.4218 107.776 21.824C108.327 22.2061 108.753 22.741 109.003 23.3627C109.277 24.0399 109.413 24.7653 109.403 25.496V26.032ZM107.459 24.7654C107.463 24.1992 107.275 23.6484 106.925 23.2027C106.769 23.0274 106.576 22.8888 106.36 22.7966C106.143 22.7044 105.91 22.661 105.675 22.6694C105.438 22.6645 105.204 22.7095 104.986 22.8014C104.768 22.8933 104.572 23.03 104.411 23.2027C104.035 23.6408 103.805 24.1851 103.752 24.76L107.459 24.7654Z'
                    fill='white'
                />
                <path
                    d='M110.912 23.6106C110.912 23.1333 110.888 22.4026 110.845 21.424H112.712C112.744 21.6666 112.773 21.9573 112.805 22.2906C112.837 22.624 112.853 22.9013 112.864 23.112C113.065 22.7721 113.293 22.4492 113.547 22.1467C113.767 21.888 114.033 21.672 114.331 21.5093C114.644 21.3401 114.996 21.2547 115.352 21.2613C115.622 21.2547 115.892 21.288 116.152 21.36L115.901 23.088C115.589 22.9976 115.261 22.9746 114.939 23.0206C114.618 23.0666 114.309 23.1804 114.035 23.3546C113.593 23.6827 113.209 24.0809 112.896 24.5333V30.1333H110.912V23.6106Z'
                    fill='white'
                />
                <path
                    d='M117.261 33.6987C117.018 33.6594 116.778 33.6015 116.544 33.5254L116.907 32.0721C117.074 32.1172 117.244 32.1537 117.416 32.1814C117.608 32.2155 117.802 32.2333 117.997 32.2347C118.886 32.2347 119.548 31.6667 119.981 30.5307L120.112 30.2081L116.899 21.4241H119.032L120.664 26.3334C120.873 26.9649 121.042 27.6088 121.171 28.2614C121.323 27.6321 121.499 27.0027 121.704 26.3734L123.331 21.4241H125.339L122.139 30.2481C121.897 30.9588 121.568 31.6369 121.16 32.2667C120.848 32.7425 120.423 33.133 119.923 33.4027C119.386 33.6647 118.794 33.7928 118.197 33.7761C117.884 33.7773 117.57 33.7514 117.261 33.6987Z'
                    fill='white'
                />
                <path
                    d='M42.2642 7.30127H45.8108L45.7122 8.15994H43.2988V9.82127H45.5735V10.6213H43.2988V12.4613H45.8402L45.7522 13.3333H42.2642V7.30127Z'
                    fill='white'
                />
                <path
                    d='M48.2615 10.2559L46.3948 7.30127H47.5788L48.8482 9.47194L50.1815 7.30127H51.3308L49.4642 10.2186L51.4828 13.3333H50.2828L48.8455 10.9599L47.3815 13.3333H46.2322L48.2615 10.2559Z'
                    fill='white'
                />
                <path
                    d='M52.248 7.30127H53.8747C54.6374 7.30127 55.2223 7.45683 55.6294 7.76794C55.8319 7.92727 55.9929 8.13315 56.0988 8.36808C56.2046 8.60301 56.2522 8.86002 56.2374 9.11727C56.2487 9.48471 56.1562 9.84791 55.9707 10.1653C55.7897 10.4636 55.5256 10.7027 55.2107 10.8533C54.8609 11.0186 54.4776 11.1008 54.0907 11.0933C53.8221 11.0895 53.5544 11.0618 53.2907 11.0106V13.3333H52.248V7.30127ZM54.8614 9.9946C54.9751 9.88535 55.0639 9.75272 55.1214 9.60586C55.179 9.45899 55.204 9.3014 55.1947 9.14394C55.2083 8.99303 55.1845 8.84111 55.1254 8.70158C55.0664 8.56206 54.9738 8.43923 54.856 8.34394C54.5559 8.16116 54.2067 8.07548 53.856 8.0986H53.2907V10.2319C53.4889 10.2676 53.6894 10.2881 53.8907 10.2933C54.2403 10.3195 54.587 10.2128 54.8614 9.9946Z'
                    fill='white'
                />
                <path d='M57.2666 7.30127H58.3066V12.4426H60.8026L60.7146 13.3333H57.2666V7.30127Z' fill='white' />
                <path
                    d='M62.3868 13.0373C61.9497 12.7914 61.5967 12.4198 61.3735 11.9707C61.1309 11.4729 61.0111 10.9243 61.0242 10.3707C61.0114 9.79219 61.1395 9.21928 61.3975 8.70134C61.634 8.23447 62.0057 7.84973 62.4642 7.59734C62.9774 7.32853 63.5518 7.19802 64.1308 7.21867C64.6783 7.20398 65.22 7.33366 65.7015 7.59467C66.1359 7.84147 66.4862 8.21309 66.7068 8.66134C66.9446 9.15393 67.0625 9.6958 67.0508 10.2427C67.0653 10.8276 66.9362 11.4072 66.6748 11.9307C66.4361 12.3959 66.0649 12.7801 65.6082 13.0347C65.1039 13.3029 64.5392 13.437 63.9682 13.424C63.416 13.438 62.8702 13.3045 62.3868 13.0373ZM65.0855 12.2987C65.3795 12.1039 65.608 11.8251 65.7415 11.4987C65.8911 11.125 65.9646 10.7251 65.9575 10.3227C65.9642 9.92758 65.8991 9.53454 65.7655 9.16267C65.6458 8.84058 65.4321 8.56186 65.1522 8.36267C64.8274 8.16398 64.4542 8.05884 64.0735 8.05884C63.6928 8.05884 63.3195 8.16398 62.9948 8.36267C62.7 8.5672 62.4704 8.85236 62.3335 9.18401C62.1818 9.5423 62.1064 9.92829 62.1121 10.3173C62.1057 10.7182 62.1763 11.1165 62.3202 11.4907C62.4479 11.833 62.6815 12.1257 62.987 12.3262C63.2925 12.5266 63.6539 12.6244 64.0188 12.6053C64.3974 12.6147 64.7697 12.5076 65.0855 12.2987Z'
                    fill='white'
                />
                <path
                    d='M68.2107 7.30142H70.0987C70.6518 7.26227 71.2023 7.40763 71.664 7.71476C71.8412 7.85384 71.9822 8.0336 72.075 8.23879C72.1679 8.44398 72.2098 8.66855 72.1974 8.89342C72.2036 9.18583 72.1377 9.47526 72.0054 9.73609C71.8897 9.9648 71.7281 10.1672 71.5307 10.3308C71.3542 10.4732 71.1562 10.5867 70.944 10.6668L72.8107 13.3334H71.5974L70.0267 10.9334H69.2614V13.3334H68.2107V7.30142ZM69.8454 10.1548C70.077 10.1598 70.3064 10.1095 70.5147 10.0081C70.7023 9.91802 70.8604 9.77653 70.9707 9.60009C71.0807 9.41176 71.137 9.19685 71.1334 8.97876C71.1441 8.85289 71.1234 8.72632 71.0731 8.61042C71.0229 8.49452 70.9446 8.39293 70.8454 8.31476C70.5953 8.15824 70.3024 8.08452 70.008 8.10409H69.2534V10.1654L69.8454 10.1548Z'
                    fill='white'
                />
                <path
                    d='M73.624 7.30127H77.1787L77.0774 8.15994H74.6774V9.82127H76.9307V10.6213H74.656V12.4613H77.1974L77.12 13.3333H73.624V7.30127Z'
                    fill='white'
                />
                <path d='M81.0454 7.30127H82.0854V13.3333H81.0454V7.30127Z' fill='white' />
                <path
                    d='M84.8401 8.1706H82.9734L83.0641 7.30127H87.7574L87.6614 8.1706H85.8827V13.3333H84.8401V8.1706Z'
                    fill='white'
                />
                <path
                    d='M92.2775 13.0372C91.8412 12.7912 91.4891 12.4195 91.2668 11.9706C91.0221 11.4735 90.9013 10.9245 90.9148 10.3706C90.9021 9.7921 91.0302 9.21919 91.2882 8.70125C91.5253 8.23486 91.8969 7.85029 92.3548 7.59725C92.8641 7.32711 93.4345 7.19301 94.0108 7.20791C94.5647 7.193 95.1126 7.3265 95.5975 7.59458C96.0325 7.84059 96.383 8.21242 96.6028 8.66125C96.8416 9.15361 96.9604 9.6955 96.9495 10.2426C96.9643 10.8279 96.8342 11.4077 96.5708 11.9306C96.3329 12.3964 95.9615 12.7807 95.5042 13.0346C95.0002 13.3035 94.4353 13.4376 93.8642 13.4239C93.3103 13.4388 92.7624 13.3053 92.2775 13.0372ZM94.9788 12.2986C95.2714 12.1029 95.4989 11.8243 95.6322 11.4986C95.7818 11.1249 95.8552 10.7251 95.8482 10.3226C95.8549 9.92748 95.7898 9.53445 95.6562 9.16258C95.5375 8.84067 95.3247 8.56192 95.0455 8.36258C94.7204 8.16366 94.3467 8.05839 93.9655 8.05839C93.5844 8.05839 93.2106 8.16366 92.8855 8.36258C92.5907 8.56711 92.3611 8.85227 92.2242 9.18391C92.0737 9.54259 91.9984 9.92831 92.0028 10.3172C91.9964 10.7181 92.067 11.1164 92.2108 11.4906C92.3381 11.8195 92.5598 12.1034 92.8482 12.3066C93.1639 12.515 93.5368 12.6194 93.9148 12.6052C94.2924 12.613 94.6634 12.5061 94.9788 12.2986Z'
                    fill='white'
                />
                <path
                    d='M98.104 7.30127H99.2987L102.283 11.9759C102.256 11.5306 102.245 11.1226 102.245 10.7466V7.30127H103.211V13.3333H102.053L99.024 8.58394C99.0533 9.11727 99.0693 9.59994 99.0693 10.0186V13.3333H98.104V7.30127Z'
                    fill='white'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M27.5975 7.33325H16.5281C11.3095 7.33325 9.40015 9.24259 9.40015 14.4613V25.5386C9.40015 30.7573 11.3095 32.6666 16.5281 32.6666H27.5975C32.8161 32.6666 34.7281 30.7573 34.7281 25.5386V14.4613C34.7335 9.24259 32.8241 7.33325 27.5975 7.33325Z'
                    fill='#C8102E'
                />
                <path
                    d='M14.4161 20H15.0881V23.3147H14.4161V21.968H12.8988V23.3147H12.2268V20H12.8988V21.3333H14.4161V20ZM18.2028 21.896C18.2028 22.4293 17.9361 22.7227 17.4508 22.7227C16.9655 22.7227 16.6935 22.4293 16.6935 21.8667V20H16.0215V21.8933C16.0215 22.824 16.5388 23.3573 17.4401 23.3573C18.3415 23.3573 18.8748 22.824 18.8748 21.864V20H18.2028V21.896ZM25.9681 22.2773L25.2161 20H24.6641L23.9175 22.28L23.1841 20H22.4695L23.6241 23.3147H24.1815L24.9308 21.1387L25.6828 23.3147H26.2455L27.4001 20H26.7015L25.9681 22.2773ZM28.6028 21.8853H29.8268V21.2853H28.6028V20.608H30.3788V20H27.9415V23.3067H30.4428V22.7093H28.6028V21.8853ZM31.2428 23.3067H31.9068V20H31.2428V23.3067ZM20.2028 22.6213L19.9015 23.3093H19.2161L20.6641 20H21.2561L22.7095 23.3093H22.0161L21.7308 22.624L20.2028 22.6213ZM20.4535 22.024H21.4641L20.9575 20.84L20.4535 22.024Z'
                    fill='white'
                />
                <path
                    d='M22.0668 15.7841C20.9489 15.7834 19.8769 15.339 19.0864 14.5485C18.2959 13.758 17.8515 12.6861 17.8508 11.5681H18.4455C18.4455 12.5286 18.827 13.4497 19.5062 14.1288C20.1853 14.8079 21.1064 15.1894 22.0668 15.1894C23.0273 15.1894 23.9484 14.8079 24.6275 14.1288C25.3066 13.4497 25.6882 12.5286 25.6882 11.5681H26.2828C26.2821 12.6861 25.8377 13.758 25.0472 14.5485C24.2567 15.339 23.1848 15.7834 22.0668 15.7841Z'
                    fill='white'
                />
            </g>
            <defs>
                <clipPath id='clip0_2465_55584'>
                    <rect width='133.333' height='40' fill='white' transform='translate(0.664062)' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default AppGallery;

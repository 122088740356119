import { useEffect, useState } from 'react';

export const useCountdown = (seconds: number) => {
    const [coundown, setCoundown] = useState(seconds);

    useEffect(() => {
        setCoundown(seconds);
    }, [seconds]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCoundown((value) => {
                if (value > 0) {
                    return value - 1;
                }
                clearInterval(interval);
                return value;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [seconds]);

    return coundown;
};

import { FC, useEffect, useRef } from 'react';
import { TextFieldCell } from '@indriver/nova';
import addMonths from 'date-fns/addMonths';
import addMinutes from 'date-fns/addMinutes';
import isSameDay from 'date-fns/isSameDay';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'next-i18next';
import { roundToNearest15Minutes } from 'shared/lib/round-to-nearest-15-minutes';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import { useResolveLang } from 'shared/lib/date-fns-i18n';
import { InputDatepickerWrapper, StyledInput } from './input-datepicker.styles';

interface IInputDatepickerProps {
    date?: Date;
    placeholder?: string;
    timeZone?: string;
    invalid?: boolean;
    onChange: (date: Date | string) => void;
    onInputClick: () => void;
    dir?: 'ltr' | 'rtl';
}

const InputDatepicker: FC<IInputDatepickerProps> = ({
    date,
    onChange,
    placeholder,
    timeZone,
    invalid,
    onInputClick,
    dir = 'ltr',
}) => {
    const filterPassedTime = (time: Date) => {
        const currentDate = roundToNearest15Minutes(timeZone ? utcToZonedTime(new Date(), timeZone) : new Date());
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };
    const { i18n, t } = useTranslation();

    const datePickerWrapperRef = useRef<HTMLDivElement>(null);

    // TODO: ref у react-datepicker это компонент нужно как-то получить тайм лист
    useEffect(() => {
        if (datePickerWrapperRef.current) {
            const timeListContainer = datePickerWrapperRef.current.querySelector('.react-datepicker__time-list');
            const selectedTime =
                timeListContainer &&
                (timeListContainer.querySelector('.react-datepicker__time-list-item--selected') as HTMLElement);
            if (selectedTime && timeListContainer) {
                timeListContainer.scrollTo(0, selectedTime.offsetTop);
            }
        }
    }, [date]);
    const locale = useResolveLang(i18n.resolvedLanguage);

    return (
        <InputDatepickerWrapper value={date} dir={dir} ref={datePickerWrapperRef}>
            {date && <span className='placeholder'>{placeholder}</span>}
            <ReactDatePicker
                selected={date}
                timeIntervals={15}
                showTimeSelect
                timeFormat='HH:mm'
                // dateFormat={i18n.language.includes('en') ? 'eee, dd MMMM' : 'eeeeee, dd MMMM'}
                dateFormat={i18n.language.includes('en') ? 'eee, dd MMM, HH:mm' : 'eeeeee, dd MMM, HH:mm'}
                locale={locale}
                // locale={i18n.language}
                placeholderText={placeholder}
                timeCaption={t('order.time')}
                minDate={new Date()}
                maxDate={addMonths(new Date(), 1)}
                calendarStartDay={i18n.language === 'es-MX' ? 0 : undefined}
                filterTime={filterPassedTime}
                customInput={<TextFieldCell dir={dir} invalid={invalid} className={StyledInput} />}
                onInputClick={onInputClick}
                onSelect={(value) => {
                    if (isSameDay(value, new Date())) {
                        const currentDate = addMinutes(roundToNearest15Minutes(new Date()), 15);
                        onChange(currentDate);
                    } else if (date && !isSameDay(value, date)) {
                        const currentDate = setMinutes(setHours(value, 9), 0);
                        onChange(currentDate);
                    }
                }}
                onChange={(value) => {
                    if (value) {
                        onChange(value);
                    } else {
                        onChange('');
                    }
                }}
            />
        </InputDatepickerWrapper>
    );
};

export default InputDatepicker;

import { FC } from 'react';

interface IProps {
    color?: string;
    size?: string | number;
    onClick?: () => void;
}

const RoundClose: FC<IProps> = ({ size = 24, color = '#848E9C', ...props }) => {
    return (
        <svg width={size} height={size} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 22.5c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10ZM8.306 10.284a1.045 1.045 0 1 1 1.478-1.478L12 11.023l2.216-2.216a1.045 1.045 0 0 1 1.478 1.477L13.478 12.5l2.216 2.216a1.045 1.045 0 0 1-1.478 1.478L12 13.978l-2.216 2.217a1.045 1.045 0 0 1-1.478-1.478l2.217-2.217-2.217-2.216Z'
                fill={color}
            />
        </svg>
    );
};

export default RoundClose;

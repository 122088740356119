import { Close } from '@indriver/mireska';
import { Input, Loader, Text, TextFieldCell } from '@indriver/nova';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import useDebounce from 'shared/hooks/useDebounce';
import { IAutocompleteCity } from 'common/api';
import { useTranslation } from 'next-i18next';
import { createPortal } from 'react-dom';
import useDisableBodyScroll from 'shared/hooks/use-disable-body-scroll';
import RoundClose from 'shared/ui/round-close';
import { variables } from '@indriver/yrel';
import * as Sentry from '@sentry/nextjs';
import {
    CloseBtn,
    Dropdown,
    DropdownItem,
    InputSearchWrapper,
    MobileTextFieldWrapper,
    ModalCity,
    ModalContent,
    ModalDropdown,
    ModalInputWrapper,
    ModalTitle,
    StyledInputSearch,
    MobilePostfix,
} from './mobile-input-search.styles';

export interface ISearchItem {
    readonly value: string | number;
    readonly label: string;
    readonly data: IAutocompleteCity;
}

interface IInputSearchProps {
    loadData: (value: string) => Promise<ISearchItem[]>;
    onSelect: (item?: ISearchItem) => void;
    selectedItem?: ISearchItem;
    placeholder?: string;
    startSearching?: number;
    invalid?: boolean;
    changeBtn?: ReactElement | null | '';
    onClickWrapper?: () => void;
    dir?: 'ltr' | 'rtl';
}

const MobileInputSearch = ({
    placeholder = '',
    invalid = false,
    loadData,
    onSelect,
    selectedItem,
    changeBtn,
    startSearching = 3,
    onClickWrapper,
    dir = 'ltr',
}: IInputSearchProps) => {
    const ref = useRef(null);
    const [items, setItems] = useState<ISearchItem[]>([]);
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const debouncedSearch = useDebounce<string>(search, 600);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState<ISearchItem | undefined | null>(null);
    const { t } = useTranslation();
    const fetchCities = useCallback(async (value: string): Promise<boolean> => {
        try {
            setIsLoading(true);

            const result = await loadData(value);
            setItems(result);
            setIsLoading(false);
            return true;
        } catch (error) {
            Sentry.captureException(error);
            return false;
        }
    }, []);

    useEffect(() => {
        if (debouncedSearch && debouncedSearch.length >= startSearching && !selectedItem?.value) {
            fetchCities(debouncedSearch)
                .then((r) => r)
                .catch((e) => Sentry.captureException(e));
        } else {
            setItems([]);
            setIsLoading(false);
        }
    }, [debouncedSearch, fetchCities, selectedItem?.value, startSearching]);

    useEffect(() => {
        if (selectedItem?.value) {
            setSelectedValue(selectedItem);
        } else {
            setSelectedValue(null);
        }
    }, [selectedItem?.value]);

    useEffect(() => {
        if (selectedItem?.value && selectedItem?.value !== selectedValue?.value) {
            setSearch(selectedItem.label);
        } else if (typeof selectedValue === 'undefined') {
            setSearch(search);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
        } else if (selectedValue === null && selectedItem === '' && search.length > 0) {
            setSearch('');
            setSelectedValue(null);
        }
    }, [selectedItem?.value, selectedValue?.value]);

    const handleSelect = (item?: ISearchItem) => {
        onSelect(item);
        if (item) {
            setSelectedValue(item);
        } else {
            setSelectedValue(undefined);
        }
    };

    useDisableBodyScroll(isOpen);

    const modal = () =>
        createPortal(
            <ModalCity>
                <ModalTitle>
                    <div />
                    <Text size='l' weight='bold'>
                        {placeholder}
                    </Text>

                    <CloseBtn onClick={() => setIsOpen(false)}>
                        <Close size={20} />
                    </CloseBtn>
                </ModalTitle>
                <ModalContent>
                    <ModalInputWrapper value={selectedItem?.label ?? search}>
                        {/* {search && <span className='placeholder'>{t('order.input_city')}</span>} */}
                        <Input
                            dir={dir}
                            /* eslint-disable-next-line jsx-a11y/no-autofocus */
                            autoFocus
                            design='floating'
                            className={StyledInputSearch}
                            autoComplete='off'
                            placeholder={t('order.input_city')}
                            value={selectedItem?.label ?? search}
                            postfix={
                                isLoading ? (
                                    <Loader size='s' design='primary' />
                                ) : search.length > 0 ? (
                                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        tabIndex={-1}
                                        role='button'
                                        onClick={() => {
                                            handleSelect();
                                            setSearch('');
                                            setItems([]);
                                        }}>
                                        <RoundClose size={20} color={variables['text-and-icon-secondary']} />
                                    </div>
                                ) : null
                            }
                            onFocus={() => setIsOpen(true)}
                            onInput={(event) => {
                                handleSelect(undefined);
                                setSearch(event.currentTarget.value);
                            }}
                            onChange={(event, data) => {
                                handleSelect(undefined);
                                setSearch(data.value);
                            }}
                        />
                    </ModalInputWrapper>
                </ModalContent>
                <ModalDropdown>
                    {items?.length > 0 && (
                        <Dropdown ref={ref}>
                            {items.map((city) => (
                                <DropdownItem
                                    key={city.value}
                                    title={city.label}
                                    onClick={() => {
                                        setIsOpen(false);
                                        handleSelect(city);
                                        setSearch(city.label);
                                    }}>
                                    <div>{city.label}</div>
                                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                    {/* @ts-ignore */}
                                    <span>{city?.data?.sub_name}</span>
                                </DropdownItem>
                            ))}
                        </Dropdown>
                    )}
                </ModalDropdown>
            </ModalCity>,
            window.document.getElementById('mobile-city-root') as Element,
        );
    return (
        <>
            <InputSearchWrapper value={search} onClick={onClickWrapper}>
                {search && <span className='placeholder'>{placeholder}</span>}
                <MobileTextFieldWrapper>
                    <TextFieldCell
                        dir={dir}
                        invalid={invalid}
                        className={StyledInputSearch}
                        placeholder={placeholder}
                        // id={'pickerId'}
                        value={selectedItem?.label ?? search}
                        onClick={() => setIsOpen(true)}
                        // onClick={props.onClickCell}
                    />
                    <MobilePostfix>{changeBtn}</MobilePostfix>
                </MobileTextFieldWrapper>
            </InputSearchWrapper>
            {/* rootSelector='#city_to_field' */}
            <>{isOpen && modal()}</>
        </>
    );
};

export default MobileInputSearch;

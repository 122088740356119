import { useState, useEffect, FC } from 'react';
import { motion } from 'framer-motion';
import { variables } from '@indriver/yrel';

interface IProps {
    isExistBids: boolean;
}

const ProgressBar: FC<IProps> = ({ isExistBids }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        let interval: number | null = null;
        if (isExistBids && progress !== 100) {
            setProgress(98);
            interval = window.setTimeout(() => {
                setProgress(100);
            }, 400);
        } else if (progress < 50) {
            interval = window.setInterval(() => {
                setProgress(progress + 0.03125);
            }, 37.5);
        } else if (progress < 90) {
            interval = window.setInterval(() => {
                setProgress(progress + 0.015625);
            }, 46.875);
        }

        return () => {
            interval && window.clearInterval(interval);
        };
    }, [progress, isExistBids]);

    const progressBarStyle = {
        width: `${progress + 2}%`,
        height: '100%',
        borderRadius: 4,
        backgroundColor: variables['background-brand'],
        transition: '.3s linear',
        zIndex: 2,
    };

    const backgroundStyle = {
        width: '100%',
        height: '100%',
        borderRadius: 4,
        backgroundColor: variables['background-secondary'],
        zIndex: 1,
    };

    const variants = {
        hidden: { opacity: 0, scale: 0 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: { duration: 0.3, ease: 'linear' },
        },
        completed: {
            opacity: 0,
            scale: 1,
            transition: { duration: 0.3, ease: 'linear' },
        },
    };

    const animate = progress === 100 ? 'completed' : 'visible';

    return (
        <>
            <motion.div style={backgroundStyle} variants={variants} initial='hidden' animate={animate} />
            <motion.div style={progressBarStyle} variants={variants} initial='hidden' animate={animate} />
        </>
    );
};

export default ProgressBar;

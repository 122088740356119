const AppStore = () => {
    return (
        <svg width='120' height='40' viewBox='0 0 120 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_2465_55580)'>
                <path
                    d='M110.135 -0.000114141H9.53468C9.16798 -0.000114141 8.80568 -0.000114141 8.43995 0.00188586C8.1338 0.00388586 7.83009 0.00969586 7.521 0.0145859C6.84951 0.0224877 6.17961 0.0815621 5.5171 0.191296C4.85552 0.303418 4.21467 0.514802 3.61622 0.818296C3.0185 1.12436 2.47235 1.52205 1.99757 1.99696C1.5203 2.47052 1.12246 3.0179 0.81935 3.61805C0.5154 4.21699 0.304641 4.85882 0.19435 5.52137C0.0830109 6.18307 0.0230984 6.85241 0.01515 7.52337C0.00587 7.82997 0.00489 8.13759 0 8.44423V31.5585C0.00489 31.869 0.00587 32.1698 0.01515 32.4804C0.0231008 33.1513 0.0830134 33.8206 0.19435 34.4823C0.304336 35.1452 0.515108 35.7874 0.81935 36.3866C1.12233 36.9848 1.52022 37.53 1.99757 38.0009C2.47054 38.4779 3.01705 38.8759 3.61622 39.1796C4.21467 39.4839 4.85545 39.6965 5.5171 39.8104C6.17972 39.9192 6.84956 39.9783 7.521 39.9872C7.83009 39.994 8.1338 39.9979 8.43995 39.9979C8.80567 39.9999 9.168 39.9999 9.53468 39.9999H110.135C110.494 39.9999 110.859 39.9999 111.219 39.9979C111.523 39.9979 111.836 39.994 112.141 39.9872C112.811 39.9788 113.479 39.9197 114.141 39.8104C114.804 39.6957 115.448 39.4831 116.049 39.1796C116.647 38.8757 117.193 38.4777 117.666 38.0009C118.142 37.5281 118.541 36.9833 118.848 36.3866C119.15 35.787 119.358 35.1448 119.467 34.4823C119.578 33.8205 119.64 33.1514 119.652 32.4804C119.656 32.1698 119.656 31.869 119.656 31.5585C119.664 31.1952 119.664 30.8339 119.664 30.4647V9.53602C119.664 9.16981 119.664 8.80653 119.656 8.44423C119.656 8.13759 119.656 7.82997 119.652 7.52333C119.64 6.85231 119.578 6.18312 119.467 5.52133C119.358 4.85917 119.149 4.21739 118.848 3.61801C118.23 2.41508 117.252 1.43591 116.049 0.818206C115.448 0.515453 114.804 0.304126 114.141 0.191206C113.48 0.0809886 112.811 0.0218936 112.141 0.0144459C111.836 0.00956586 111.523 0.00370586 111.219 0.00175586C110.859 -0.000244141 110.494 -0.000244141 110.135 -0.000244141V-0.000114141Z'
                    fill='#A6A6A6'
                />
                <path
                    d='M8.44487 39.125C8.14019 39.125 7.84287 39.1211 7.54058 39.1143C6.91436 39.1061 6.2896 39.0516 5.67144 38.9512C5.09503 38.8519 4.53664 38.6673 4.0147 38.4033C3.49754 38.1415 3.02585 37.7983 2.6177 37.3867C2.20364 36.98 1.85891 36.5082 1.59719 35.9902C1.33258 35.4688 1.14945 34.9099 1.05419 34.333C0.951311 33.7131 0.895651 33.0863 0.887687 32.458C0.881347 32.2471 0.873047 31.5449 0.873047 31.5449V8.44434C0.873047 8.44434 0.881887 7.75293 0.887737 7.5498C0.895363 6.92248 0.950699 6.29665 1.05327 5.67773C1.14871 5.09925 1.33197 4.53875 1.59673 4.01563C1.85749 3.49794 2.2003 3.02586 2.61187 2.61768C3.02297 2.20562 3.49617 1.8606 4.01421 1.59521C4.53495 1.33209 5.09228 1.14873 5.66753 1.05127C6.28772 0.949836 6.91465 0.894996 7.54304 0.88721L8.44536 0.875H111.214L112.127 0.8877C112.75 0.895099 113.371 0.94945 113.985 1.05029C114.566 1.14898 115.13 1.33362 115.656 1.59814C116.694 2.13299 117.539 2.97916 118.071 4.01807C118.332 4.53758 118.512 5.09351 118.607 5.66699C118.71 6.29099 118.768 6.92174 118.78 7.5542C118.783 7.8374 118.783 8.1416 118.783 8.44434C118.791 8.81934 118.791 9.17627 118.791 9.53613V30.4648C118.791 30.8281 118.791 31.1826 118.783 31.54C118.783 31.8652 118.783 32.1631 118.779 32.4697C118.768 33.0909 118.711 33.7104 118.608 34.3232C118.515 34.9043 118.333 35.4675 118.068 35.9932C117.805 36.5056 117.462 36.9733 117.053 37.3789C116.644 37.7927 116.172 38.1379 115.653 38.4014C115.128 38.6674 114.566 38.8527 113.985 38.9512C113.367 39.0522 112.742 39.1067 112.116 39.1143C111.823 39.1211 111.517 39.125 111.219 39.125L110.135 39.127L8.44487 39.125Z'
                    fill='#1B1B1C'
                />
                <path
                    d='M24.7689 20.3006C24.7796 19.4659 25.0013 18.6475 25.4134 17.9216C25.8254 17.1956 26.4143 16.5857 27.1254 16.1485C26.6737 15.5034 26.0777 14.9724 25.3849 14.5979C24.6921 14.2233 23.9215 14.0155 23.1343 13.9908C21.455 13.8146 19.8271 14.9957 18.9714 14.9957C18.0991 14.9957 16.7816 14.0083 15.3629 14.0375C14.4452 14.0672 13.5509 14.334 12.767 14.8121C11.9831 15.2901 11.3364 15.9631 10.89 16.7654C8.95595 20.1138 10.3985 25.0349 12.2511 27.7415C13.178 29.0668 14.2613 30.5473 15.6788 30.4948C17.0658 30.4372 17.5839 29.6103 19.2582 29.6103C20.9169 29.6103 21.403 30.4948 22.8492 30.4614C24.3376 30.4372 25.2753 29.1302 26.1697 27.7923C26.8357 26.8479 27.3481 25.8042 27.6881 24.6997C26.8234 24.334 26.0855 23.7218 25.5664 22.9395C25.0472 22.1572 24.7699 21.2394 24.7689 20.3006Z'
                    fill='white'
                />
                <path
                    d='M22.0372 12.2108C22.8487 11.2366 23.2485 9.98445 23.1517 8.72021C21.9119 8.85044 20.7666 9.44299 19.9441 10.3798C19.542 10.8375 19.234 11.3699 19.0378 11.9467C18.8415 12.5235 18.7609 13.1333 18.8004 13.7412C19.4206 13.7476 20.0341 13.6132 20.5947 13.3481C21.1554 13.083 21.6486 12.6942 22.0372 12.2108Z'
                    fill='white'
                />
                <path
                    d='M42.3022 27.1397H37.5688L36.4321 30.4961H34.4272L38.9106 18.0781H40.9936L45.477 30.4961H43.438L42.3022 27.1397ZM38.0591 25.5908H41.8111L39.9615 20.1436H39.9097L38.0591 25.5908Z'
                    fill='white'
                />
                <path
                    d='M55.1597 25.9697C55.1597 28.7832 53.6538 30.5908 51.3814 30.5908C50.8057 30.6209 50.2332 30.4883 49.7294 30.2082C49.2256 29.928 48.8109 29.5117 48.5327 29.0068H48.4897V33.4912H46.6313V21.4423H48.4302V22.9482H48.4644C48.7553 22.4457 49.1771 22.0316 49.6847 21.7497C50.1923 21.4679 50.7669 21.3289 51.3472 21.3476C53.645 21.3476 55.1597 23.164 55.1597 25.9697ZM53.2495 25.9697C53.2495 24.1367 52.3023 22.9316 50.857 22.9316C49.437 22.9316 48.482 24.1621 48.482 25.9697C48.482 27.7939 49.437 29.0156 50.857 29.0156C52.3023 29.0156 53.2495 27.8193 53.2495 25.9697Z'
                    fill='white'
                />
                <path
                    d='M65.1245 25.9697C65.1245 28.7832 63.6187 30.5908 61.3462 30.5908C60.7706 30.6209 60.1981 30.4883 59.6943 30.2081C59.1905 29.928 58.7758 29.5117 58.4976 29.0068H58.4546V33.4912H56.5962V21.4423H58.395V22.9482H58.4292C58.7201 22.4457 59.1419 22.0315 59.6495 21.7497C60.1571 21.4679 60.7317 21.3289 61.312 21.3476C63.6099 21.3476 65.1245 23.164 65.1245 25.9697ZM63.2144 25.9697C63.2144 24.1367 62.2671 22.9316 60.8218 22.9316C59.4019 22.9316 58.4468 24.1621 58.4468 25.9697C58.4468 27.7939 59.4019 29.0156 60.8218 29.0156C62.2671 29.0156 63.2144 27.8193 63.2144 25.9697H63.2144Z'
                    fill='white'
                />
                <path
                    d='M71.7105 27.0362C71.8482 28.2676 73.0445 29.0762 74.6792 29.0762C76.2456 29.0762 77.3726 28.2676 77.3726 27.1572C77.3726 26.1934 76.6929 25.6162 75.0835 25.2207L73.4742 24.833C71.1939 24.2822 70.1353 23.2158 70.1353 21.4854C70.1353 19.3428 72.0025 17.8711 74.6538 17.8711C77.2778 17.8711 79.0767 19.3428 79.1372 21.4854H77.2612C77.1489 20.2461 76.1245 19.4981 74.6274 19.4981C73.1304 19.4981 72.106 20.2549 72.106 21.3565C72.106 22.2344 72.7603 22.751 74.3608 23.1465L75.729 23.4824C78.2769 24.0849 79.3355 25.1084 79.3355 26.9248C79.3355 29.248 77.4849 30.7031 74.5415 30.7031C71.7876 30.7031 69.9282 29.2822 69.8081 27.0361L71.7105 27.0362Z'
                    fill='white'
                />
                <path
                    d='M83.3462 19.2998V21.4424H85.0679V22.9141H83.3462V27.9053C83.3462 28.6807 83.6909 29.042 84.4477 29.042C84.6521 29.0384 84.8562 29.0241 85.0591 28.999V30.4619C84.7188 30.5255 84.3729 30.5543 84.0268 30.5478C82.1938 30.5478 81.479 29.8593 81.479 28.1035V22.9141H80.1626V21.4424H81.479V19.2998H83.3462Z'
                    fill='white'
                />
                <path
                    d='M86.0649 25.9697C86.0649 23.1211 87.7427 21.3311 90.3589 21.3311C92.9839 21.3311 94.6538 23.1211 94.6538 25.9697C94.6538 28.8262 92.9927 30.6084 90.3589 30.6084C87.726 30.6084 86.0649 28.8262 86.0649 25.9697ZM92.7602 25.9697C92.7602 24.0156 91.8647 22.8623 90.3589 22.8623C88.853 22.8623 87.9585 24.0244 87.9585 25.9697C87.9585 27.9316 88.853 29.0762 90.3589 29.0762C91.8647 29.0762 92.7602 27.9316 92.7602 25.9697H92.7602Z'
                    fill='white'
                />
                <path
                    d='M96.186 21.4425H97.9585V22.9835H98.0015C98.1214 22.5022 98.4034 22.0769 98.8 21.779C99.1966 21.4811 99.6836 21.3288 100.179 21.3478C100.393 21.347 100.607 21.3703 100.816 21.4171V23.1554C100.546 23.0728 100.264 23.0348 99.981 23.0431C99.711 23.0321 99.4418 23.0797 99.192 23.1826C98.9421 23.2855 98.7175 23.4412 98.5335 23.6391C98.3496 23.837 98.2106 24.0724 98.1262 24.3291C98.0417 24.5857 98.0139 24.8576 98.0444 25.1261V30.4962H96.186L96.186 21.4425Z'
                    fill='white'
                />
                <path
                    d='M109.384 27.8369C109.134 29.4805 107.534 30.6084 105.486 30.6084C102.852 30.6084 101.217 28.8437 101.217 26.0127C101.217 23.1729 102.861 21.3311 105.408 21.3311C107.913 21.3311 109.488 23.0518 109.488 25.7969V26.4336H103.093V26.5459C103.064 26.8791 103.105 27.2148 103.216 27.5306C103.326 27.8464 103.502 28.1352 103.732 28.3778C103.963 28.6203 104.242 28.8111 104.552 28.9374C104.861 29.0637 105.195 29.1226 105.529 29.1103C105.968 29.1515 106.409 29.0498 106.785 28.8203C107.162 28.5909 107.455 28.246 107.62 27.8369L109.384 27.8369ZM103.102 25.1348H107.628C107.645 24.8352 107.6 24.5354 107.495 24.2541C107.39 23.9729 107.229 23.7164 107.02 23.5006C106.812 23.2849 106.561 23.1145 106.283 23.0003C106.006 22.8861 105.708 22.8305 105.408 22.8369C105.105 22.8351 104.805 22.8933 104.525 23.008C104.245 23.1227 103.99 23.2918 103.776 23.5054C103.562 23.7191 103.392 23.973 103.276 24.2527C103.16 24.5323 103.101 24.8321 103.102 25.1348V25.1348Z'
                    fill='white'
                />
                <path
                    d='M37.8262 8.73065C38.2158 8.70269 38.6067 8.76155 38.9709 8.90298C39.335 9.04441 39.6632 9.26489 39.9317 9.54852C40.2003 9.83214 40.4026 10.1718 40.524 10.5431C40.6454 10.9144 40.6829 11.308 40.6338 11.6955C40.6338 13.6017 39.6035 14.6975 37.8262 14.6975H35.6709V8.73065H37.8262ZM36.5976 13.8536H37.7226C38.0011 13.8703 38.2797 13.8246 38.5382 13.72C38.7967 13.6154 39.0287 13.4545 39.2172 13.2489C39.4057 13.0433 39.546 12.7983 39.6278 12.5317C39.7097 12.2651 39.7311 11.9836 39.6904 11.7076C39.7281 11.4328 39.7046 11.153 39.6215 10.8884C39.5383 10.6237 39.3977 10.3807 39.2096 10.1768C39.0216 9.97285 38.7907 9.81304 38.5337 9.70881C38.2766 9.60457 37.9996 9.55849 37.7226 9.57386H36.5976V13.8536Z'
                    fill='white'
                />
                <path
                    d='M41.6807 12.444C41.6524 12.1481 41.6862 11.8496 41.7801 11.5675C41.8739 11.2854 42.0257 11.0261 42.2256 10.8061C42.4256 10.5861 42.6693 10.4103 42.9411 10.2901C43.213 10.1698 43.507 10.1077 43.8042 10.1077C44.1015 10.1077 44.3955 10.1698 44.6673 10.2901C44.9392 10.4103 45.1829 10.5861 45.3828 10.8061C45.5828 11.0261 45.7345 11.2854 45.8284 11.5675C45.9222 11.8496 45.9561 12.1481 45.9278 12.444C45.9566 12.7403 45.9232 13.0392 45.8296 13.3218C45.736 13.6043 45.5843 13.8641 45.3843 14.0845C45.1843 14.3049 44.9404 14.4811 44.6683 14.6016C44.3962 14.7221 44.1019 14.7844 43.8042 14.7844C43.5066 14.7844 43.2123 14.7221 42.9402 14.6016C42.668 14.4811 42.4241 14.3049 42.2241 14.0845C42.0242 13.8641 41.8725 13.6043 41.7789 13.3218C41.6853 13.0392 41.6519 12.7403 41.6807 12.444ZM45.0137 12.444C45.0137 11.468 44.5752 10.8972 43.8057 10.8972C43.0332 10.8972 42.5987 11.468 42.5987 12.4441C42.5987 13.4279 43.0333 13.9943 43.8057 13.9943C44.5752 13.9943 45.0137 13.424 45.0137 12.444H45.0137Z'
                    fill='white'
                />
                <path
                    d='M51.5732 14.6975H50.6514L49.7207 11.3811H49.6504L48.7236 14.6975H47.8105L46.5693 10.1946H47.4707L48.2773 13.6306H48.3438L49.2695 10.1946H50.1221L51.0479 13.6306H51.1182L51.9209 10.1946H52.8096L51.5732 14.6975Z'
                    fill='white'
                />
                <path
                    d='M53.8535 10.1946H54.709V10.91H54.7754C54.888 10.653 55.0781 10.4377 55.3189 10.2939C55.5598 10.15 55.8395 10.085 56.1191 10.1077C56.3382 10.0912 56.5582 10.1243 56.7628 10.2044C56.9674 10.2845 57.1514 10.4096 57.3011 10.5704C57.4508 10.7313 57.5623 10.9237 57.6275 11.1335C57.6927 11.3434 57.7099 11.5652 57.6777 11.7825V14.6975H56.7891V12.0057C56.7891 11.282 56.4746 10.9222 55.8174 10.9222C55.6686 10.9152 55.5201 10.9406 55.3821 10.9964C55.244 11.0522 55.1197 11.1372 55.0175 11.2456C54.9154 11.354 54.8379 11.4832 54.7904 11.6243C54.7429 11.7655 54.7264 11.9152 54.7422 12.0633V14.6976H53.8535L53.8535 10.1946Z'
                    fill='white'
                />
                <path d='M59.0938 8.43677H59.9824V14.6975H59.0938V8.43677Z' fill='white' />
                <path
                    d='M61.2178 12.4441C61.1895 12.1482 61.2234 11.8496 61.3172 11.5675C61.4111 11.2854 61.5629 11.0261 61.7628 10.8061C61.9628 10.5861 62.2065 10.4104 62.4784 10.2901C62.7503 10.1698 63.0443 10.1077 63.3416 10.1077C63.6389 10.1077 63.9329 10.1698 64.2047 10.2901C64.4766 10.4104 64.7203 10.5861 64.9203 10.8061C65.1203 11.0261 65.272 11.2854 65.3659 11.5675C65.4598 11.8496 65.4936 12.1482 65.4653 12.4441C65.4942 12.7404 65.4607 13.0393 65.3671 13.3219C65.2734 13.6044 65.1217 13.8642 64.9217 14.0846C64.7217 14.305 64.4778 14.4811 64.2057 14.6017C63.9335 14.7222 63.6392 14.7845 63.3416 14.7845C63.0439 14.7845 62.7496 14.7222 62.4775 14.6017C62.2053 14.4811 61.9614 14.305 61.7614 14.0846C61.5614 13.8642 61.4097 13.6044 61.3161 13.3219C61.2225 13.0393 61.189 12.7404 61.2178 12.4441ZM64.5508 12.4441C64.5508 11.4681 64.1123 10.8973 63.3428 10.8973C62.5703 10.8973 62.1358 11.4681 62.1358 12.4441C62.1358 13.428 62.5704 13.9944 63.3428 13.9944C64.1123 13.9944 64.5508 13.4241 64.5508 12.4441H64.5508Z'
                    fill='white'
                />
                <path
                    d='M66.4009 13.4241C66.4009 12.6135 67.0044 12.1462 68.0757 12.0798L69.2954 12.0095V11.6208C69.2954 11.1453 68.981 10.8767 68.3735 10.8767C67.8774 10.8767 67.5337 11.0588 67.4351 11.3772H66.5747C66.6655 10.6038 67.3931 10.1077 68.4146 10.1077C69.5435 10.1077 70.1802 10.6697 70.1802 11.6208V14.6975H69.3247V14.0647H69.2544C69.1117 14.2917 68.9113 14.4767 68.6737 14.6009C68.436 14.7252 68.1697 14.7841 67.9019 14.7717C67.7128 14.7914 67.5218 14.7712 67.341 14.7125C67.1602 14.6538 66.9938 14.5579 66.8524 14.4309C66.711 14.3039 66.5977 14.1487 66.52 13.9753C66.4422 13.8019 66.4016 13.6141 66.4009 13.4241ZM69.2954 13.0393V12.6628L68.1958 12.7331C67.5757 12.7746 67.2944 12.9856 67.2944 13.3826C67.2944 13.7878 67.646 14.0237 68.1294 14.0237C68.2711 14.038 68.4142 14.0237 68.5502 13.9816C68.6862 13.9395 68.8123 13.8705 68.9211 13.7787C69.0299 13.6868 69.1191 13.574 69.1834 13.447C69.2477 13.32 69.2858 13.1813 69.2954 13.0393Z'
                    fill='white'
                />
                <path
                    d='M71.3481 12.4441C71.3481 11.0213 72.0796 10.1199 73.2173 10.1199C73.4987 10.1069 73.778 10.1743 74.0225 10.3142C74.267 10.4541 74.4667 10.6607 74.5981 10.9099H74.6646V8.43677H75.5532V14.6975H74.7017V13.9861H74.6314C74.4897 14.2336 74.2831 14.4376 74.0339 14.5761C73.7846 14.7146 73.5022 14.7822 73.2173 14.7717C72.0718 14.7718 71.3481 13.8704 71.3481 12.4441ZM72.2661 12.4441C72.2661 13.3992 72.7163 13.9739 73.4693 13.9739C74.2183 13.9739 74.6812 13.3909 74.6812 12.448C74.6812 11.5095 74.2134 10.9182 73.4693 10.9182C72.7212 10.9182 72.2661 11.4968 72.2661 12.4441H72.2661Z'
                    fill='white'
                />
                <path
                    d='M79.23 12.444C79.2017 12.1481 79.2356 11.8496 79.3294 11.5675C79.4232 11.2854 79.575 11.0261 79.7749 10.8061C79.9749 10.5861 80.2186 10.4103 80.4904 10.2901C80.7623 10.1698 81.0563 10.1077 81.3536 10.1077C81.6508 10.1077 81.9448 10.1698 82.2167 10.2901C82.4885 10.4103 82.7322 10.5861 82.9322 10.8061C83.1321 11.0261 83.2839 11.2854 83.3777 11.5675C83.4715 11.8496 83.5054 12.1481 83.4771 12.444C83.5059 12.7403 83.4725 13.0392 83.3789 13.3218C83.2853 13.6043 83.1336 13.8641 82.9336 14.0845C82.7336 14.3049 82.4897 14.4811 82.2176 14.6016C81.9455 14.7221 81.6512 14.7844 81.3536 14.7844C81.0559 14.7844 80.7616 14.7221 80.4895 14.6016C80.2173 14.4811 79.9735 14.3049 79.7735 14.0845C79.5735 13.8641 79.4218 13.6043 79.3282 13.3218C79.2346 13.0392 79.2012 12.7403 79.23 12.444ZM82.563 12.444C82.563 11.468 82.1245 10.8972 81.355 10.8972C80.5826 10.8972 80.148 11.468 80.148 12.4441C80.148 13.4279 80.5826 13.9943 81.355 13.9943C82.1245 13.9943 82.563 13.424 82.563 12.444Z'
                    fill='white'
                />
                <path
                    d='M84.6694 10.1946H85.5249V10.91H85.5913C85.704 10.653 85.894 10.4377 86.1348 10.2939C86.3757 10.15 86.6555 10.085 86.9351 10.1077C87.1542 10.0912 87.3742 10.1243 87.5788 10.2044C87.7834 10.2845 87.9673 10.4096 88.117 10.5704C88.2667 10.7313 88.3783 10.9237 88.4435 11.1335C88.5087 11.3434 88.5258 11.5652 88.4937 11.7825V14.6975H87.605V12.0057C87.605 11.282 87.2905 10.9222 86.6333 10.9222C86.4846 10.9152 86.336 10.9406 86.198 10.9964C86.0599 11.0522 85.9356 11.1372 85.8335 11.2456C85.7313 11.354 85.6539 11.4832 85.6063 11.6243C85.5588 11.7655 85.5423 11.9152 85.5581 12.0633V14.6976H84.6694V10.1946Z'
                    fill='white'
                />
                <path
                    d='M93.5151 9.07349V10.2151H94.4907V10.9636H93.5151V13.2791C93.5151 13.7507 93.7095 13.9573 94.1519 13.9573C94.2651 13.9569 94.3783 13.9501 94.4907 13.9368V14.677C94.3311 14.7055 94.1694 14.7207 94.0073 14.7224C93.019 14.7224 92.6255 14.3747 92.6255 13.5066V10.9636H91.9106V10.215H92.6255V9.07349H93.5151Z'
                    fill='white'
                />
                <path
                    d='M95.7046 8.43677H96.5854V10.9182H96.6558C96.7739 10.6589 96.9691 10.4423 97.2148 10.298C97.4605 10.1536 97.7447 10.0885 98.0288 10.1116C98.2467 10.0997 98.4646 10.1361 98.6669 10.2181C98.8692 10.3002 99.0508 10.4259 99.199 10.5862C99.3471 10.7465 99.458 10.9376 99.5238 11.1457C99.5896 11.3539 99.6086 11.5739 99.5795 11.7903V14.6975H98.69V12.0095C98.69 11.2903 98.355 10.926 97.7271 10.926C97.5743 10.9135 97.4207 10.9345 97.2769 10.9875C97.1332 11.0406 97.0027 11.1244 96.8947 11.2332C96.7867 11.3419 96.7038 11.4729 96.6517 11.6171C96.5997 11.7612 96.5797 11.915 96.5933 12.0676V14.6975H95.7046L95.7046 8.43677Z'
                    fill='white'
                />
                <path
                    d='M104.761 13.4818C104.64 13.8933 104.379 14.2493 104.022 14.4874C103.665 14.7256 103.236 14.8307 102.81 14.7845C102.513 14.7923 102.218 14.7355 101.946 14.618C101.673 14.5004 101.43 14.325 101.232 14.1039C101.034 13.8827 100.887 13.6212 100.8 13.3374C100.713 13.0535 100.689 12.7543 100.73 12.4603C100.69 12.1654 100.715 11.8655 100.801 11.5808C100.888 11.2961 101.034 11.0334 101.231 10.8103C101.428 10.5872 101.671 10.409 101.942 10.2878C102.214 10.1666 102.509 10.1052 102.806 10.1077C104.059 10.1077 104.815 10.9637 104.815 12.3777V12.6878H101.635V12.7376C101.621 12.9029 101.642 13.0692 101.696 13.226C101.75 13.3827 101.837 13.5264 101.949 13.6479C102.062 13.7693 102.199 13.8658 102.352 13.9312C102.504 13.9966 102.669 14.0295 102.834 14.0276C103.047 14.0532 103.262 14.0149 103.453 13.9177C103.644 13.8205 103.801 13.6687 103.906 13.4817L104.761 13.4818ZM101.635 12.0306H103.91C103.921 11.8795 103.9 11.7277 103.849 11.5849C103.798 11.4422 103.718 11.3117 103.614 11.2019C103.509 11.092 103.383 11.0052 103.243 10.9471C103.103 10.8889 102.953 10.8606 102.801 10.8641C102.647 10.8622 102.495 10.891 102.353 10.949C102.21 11.0069 102.081 11.0928 101.972 11.2015C101.864 11.3102 101.778 11.4396 101.72 11.582C101.662 11.7244 101.633 11.8769 101.635 12.0306H101.635Z'
                    fill='white'
                />
            </g>
            <defs>
                <clipPath id='clip0_2465_55580'>
                    <rect width='119.664' height='40' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default AppStore;

const StarIcon = () => {
    return (
        <svg
            width={18}
            height={19}
            preserveAspectRatio='xMidYMid slice'
            viewBox='0 0 12 13'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M6.054 1.649a.49.49 0 0 1 .45.296l1.209 2.808 3.044.282a.49.49 0 0 1 .278.855L8.738 7.908l.672 2.982a.49.49 0 0 1-.728.53L6.054 9.857 3.425 11.42a.49.49 0 0 1-.728-.529l.672-2.982L1.074 5.89a.49.49 0 0 1 .278-.855l3.044-.282 1.209-2.808a.49.49 0 0 1 .45-.296Z'
                fill='#FFC13C'
            />
        </svg>
    );
};

export default StarIcon;

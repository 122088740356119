import React, { FC, HTMLAttributes, ReactElement } from 'react';
import { CardWrapper } from './card.styles';

export interface ICardProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactElement | ReactElement[];
    type?: 'order';
}

const Card: FC<ICardProps> = ({ children, ...props }) => {
    return <CardWrapper {...props}>{children}</CardWrapper>;
};

export default Card;

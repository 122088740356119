import { ContainerWrapper, LayoutWrapper } from 'shared/ui/layout/layout.style';
import { FC, ReactElement } from 'react';

const Layout: FC<{ children: ReactElement | ReactElement[] }> = ({ children }) => {
    return (
        <LayoutWrapper>
            <ContainerWrapper>{children}</ContainerWrapper>
        </LayoutWrapper>
    );
};
export default Layout;

import { useCallback, useContext } from 'react';
import { GeoWrapper } from 'context/geo';
import { getUnixTime } from 'date-fns/fp';
import { getTimezoneOffset } from 'date-fns-tz';
import { EventValue, sendBloomreachAnalyticsEvent } from 'shared/lib/send-analytics-event';
import { useMe } from 'context/user';
import { ABTogglesContext } from 'context/ab-toggles';
import { logger } from '@indriver/elastic-logger';
import * as Sentry from '@sentry/nextjs';

export const useAnalytics = () => {
    const geo = useContext(GeoWrapper);
    const { toggles } = useContext(ABTogglesContext);
    const { userInfo } = useMe();

    const sendEventBloomreach = useCallback(
        async (
            event: string,
            eventValue?: EventValue,
            frequency: 'once' | 'session' | 'always' = 'always',
        ): Promise<void> => {
            const toggleName = `send_${event}_marketing_event`;
            const toggle = toggles?.experiments[toggleName];

            if (!toggle?.isEnabled) {
                return;
            }

            let verticals = [];
            try {
                verticals = toggle?.verticals && JSON.parse(toggle?.verticals as string);
            } catch (error) {
                Sentry.captureException(error);
                logger.error('Failed to parse verticals:', error);
            }

            const isIntercityAndCustomer = Array.isArray(verticals)
                ? verticals.includes('intercity')
                : (verticals?.customer || []).includes('intercity');

            // hardcode for registration, because this event has only isEnabled prop
            if (!isIntercityAndCustomer && event !== 'registration') {
                return;
            }
            const additionalPayload: EventValue = {
                timestamp: getUnixTime(new Date()),
                timezone_ofsendEventBloomreachfset:
                    getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone, new Date()) / (60 * 1000),
                vertical: 'intercity',
                role: 'customer',
                platform: 'web',
                ...(geo && { country_code: geo.code }),
                ...(userInfo?.city_id && { city_id: userInfo.city_id }),
                ...(userInfo?.id && { user_id: userInfo.id, customer_id: userInfo.id }),
            };

            await sendBloomreachAnalyticsEvent(event, { ...additionalPayload, ...eventValue }, frequency);
        },
        [geo, userInfo],
    );

    return { sendEventBloomreach };
};

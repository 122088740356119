import { FC, ReactElement, ReactNode, useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';
import Heading from 'shared/ui/heading/heading';
import { useDisableBodyScroll, useOnClickOutside } from 'shared/hooks';
import { Close } from '@indriver/mireska';
import { variables } from '@indriver/yrel';
import { ModalCard, ModalClose, ModalContent, ModalFooter, ModalWrapper } from './modal.styles';

interface IModalProps {
    opened: boolean;
    onClose?: () => void;
    title?: string | ReactNode;
    footer?: ReactNode;
    children?: ReactNode;
    rootSelector?: string;
    maxWidth?: number;
    maxHeight?: number | string;
    padding?: string;
    closeIcon?: ReactElement | null;
}

const Modal: FC<IModalProps> = ({
    opened,
    onClose,
    children,
    title,
    maxWidth = 608,
    maxHeight = '',
    padding = '64px',
    footer,
    rootSelector = 'portal-overlay',
    closeIcon,
}) => {
    const ref = useRef(null);

    const handleClickOutside = useCallback(() => {
        if (typeof onClose === 'function' && opened) {
            onClose();
        }
    }, [onClose, opened]);

    useOnClickOutside(ref, handleClickOutside);

    useDisableBodyScroll(opened);

    if (!opened) {
        return null;
    }

    return createPortal(
        <ModalWrapper>
            <ModalCard
                ref={ref}
                style={{
                    maxWidth: `${maxWidth}px`,
                    maxHeight: typeof maxHeight === 'string' ? 'none' : `${maxHeight}px`,
                    padding: padding,
                }}>
                {onClose && (
                    <ModalClose onClick={onClose}>
                        {closeIcon ?? <Close size={24} color={variables['text-and-icon-primary']} />}
                    </ModalClose>
                )}
                {title && <Heading type='h3'>{title}</Heading>}
                <ModalContent>{children}</ModalContent>
                {footer && <ModalFooter>{footer}</ModalFooter>}
            </ModalCard>
        </ModalWrapper>,
        window.document.getElementById(rootSelector) as Element,
    );
};

export default Modal;

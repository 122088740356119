import React, { useEffect, useState, useContext } from 'react';
import Image from 'next/image';
import { GeoWrapper } from 'context/geo';
import { useFakeIP } from 'features/use-fake-ip';
import { useUTMRouter } from 'shared/hooks';
import { socialNetworksLinksList } from './social-networks-list';
import { colResponsiveAligning, StyledLink } from './social-network.style';
import { geoSocialNetworks } from './geo-social-networks';

interface ISocialNetworkProps {
    facebookIcon: string;
    instagramIcon: string;
    // twitterIcon: string;
    // vkIcon: string;
    iconsPosition?: 'flex-start' | 'center' | 'flex-end';
}

export const SocialNetworks: React.FC<ISocialNetworkProps> = ({
    facebookIcon,
    instagramIcon,
    // twitterIcon,
    // vkIcon,
    iconsPosition,
}) => {
    const router = useUTMRouter();
    const [socialNetworksState, setSocialNetworksState] = useState({
        instagram: 'https://www.instagram.com/indriver/',
        facebook: 'https://www.facebook.com/indriverusa/',
        // twitter: 'https://twitter.com/inDrive',
    });
    const socialNetworksLinks = socialNetworksLinksList({
        facebookLink: socialNetworksState.facebook,
        instagramLink: socialNetworksState.instagram,
        // twitterLink: socialNetworksState.twitter,
        // vkLink: 'https://vk.com/indrive',
        facebookIcon: facebookIcon,
        instagramIcon: instagramIcon,
        // twitterIcon: twitterIcon,
        // vkIcon: vkIcon,
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const geo: any = useFakeIP(useContext(GeoWrapper)?.code);
    useEffect(() => {
        if (router.isReady) {
            // setSocialNetworksState(geoSocialNetworks(geo));
            setSocialNetworksState(geoSocialNetworks(null));
        }
    }, [geo, router.isReady]);
    return (
        <>
            {router.isReady &&
                socialNetworksLinks
                    .filter(
                        ({
                            visibleOnly,
                            hideOn,
                            countriesToHide,
                        }: {
                            visibleOnly: string[];
                            hideOn: string[];
                            countriesToHide: string[];
                        }) => {
                            if (visibleOnly.length > 0) {
                                if (!visibleOnly.includes(String(router.locale))) {
                                    return false;
                                }
                            }
                            if (hideOn.length > 0) {
                                if (hideOn.includes(String(router.locale))) {
                                    return false;
                                }
                            }
                            if (countriesToHide.length > 0) {
                                if (countriesToHide.includes(geo?.country?.isoCode?.toLowerCase())) {
                                    return false;
                                }
                            }

                            return true;
                        },
                    )
                    .map(({ href, imgSrc, imgAlt }) => (
                        <div key={href} className={colResponsiveAligning}>
                            <StyledLink iconsPosition={iconsPosition} href={href}>
                                <Image src={imgSrc} width={40} height={40} alt={imgAlt} />
                            </StyledLink>
                        </div>
                    ))}
        </>
    );
};

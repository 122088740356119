import { FC } from 'react';

interface IProps {
    size?: string | number;
}

const RoundCloseLight: FC<IProps> = ({ size = 32 }) => {
    return (
        <svg width={size} height={size} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='32' height='32' rx='16' fill='#F5F5F6' />
            <path
                d='M11.0411 9.62624C10.6506 9.23571 10.0174 9.23571 9.62688 9.62624C9.23635 10.0168 9.23635 10.6499 9.62688 11.0405L14.5864 16L9.62688 20.9596C9.23635 21.3501 9.23635 21.9833 9.62688 22.3738C10.0174 22.7643 10.6506 22.7643 11.0411 22.3738L16.0006 17.4142L20.9602 22.3738C21.3507 22.7643 21.9839 22.7643 22.3744 22.3738C22.7649 21.9833 22.7649 21.3501 22.3744 20.9596L17.4149 16L22.3744 11.0405C22.7649 10.6499 22.7649 10.0168 22.3744 9.62624C21.9839 9.23571 21.3507 9.23571 20.9602 9.62624L16.0006 14.5858L11.0411 9.62624Z'
                fill='#323942'
            />
        </svg>
    );
};

export default RoundCloseLight;

import { SOCIAL_NETWORKS_GEO } from './model';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const geoSocialNetworks = (geoData: any) => {
    const countryISOCode = geoData?.country?.isoCode?.toLowerCase();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const links: any = SOCIAL_NETWORKS_GEO;
    if (geoData === null) {
        return links.default;
    }
    if (geoData.continent && geoData.continent.code === 'SA') {
        // eslint-disable-next-line no-prototype-builtins
        if (SOCIAL_NETWORKS_GEO.hasOwnProperty(countryISOCode)) {
            return links[countryISOCode];
        }

        return links.latam;
    }
    // eslint-disable-next-line no-prototype-builtins
    if (SOCIAL_NETWORKS_GEO.hasOwnProperty(countryISOCode)) {
        return links[countryISOCode];
    }

    return links.default;
};

import { useUTMRouter } from 'shared/hooks';
import { canUseDom } from 'shared/lib/can-use-dom';

export const useFakeIP = (ip?: string | number | null) => {
    const router = useUTMRouter();
    const { fakeCountry } = router.query;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (ip === 'NO_COUNTRY' || ip?.code === 'IP_ADDRESS_RESERVED') {
        if (canUseDom && fakeCountry) {
            return {
                country: {
                    isoCode: fakeCountry,
                },
            };
        }

        return null;
    }
    if (ip === null) {
        return null;
    }

    return ip;
};

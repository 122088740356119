const imagePath = '/assets/images/icons/social_networks';

export const socialNetworksLinksList = ({
    facebookLink,
    instagramLink,
    // twitterLink,
    // vkLink,
    facebookIcon,
    instagramIcon,
}: // twitterIcon,
// vkIcon,
{
    facebookLink: string;
    instagramLink: string;
    // twitterLink: string;
    // vkLink: string;
    facebookIcon: string;
    instagramIcon: string;
    // twitterIcon: string;
    // vkIcon: string;
}) => {
    return [
        {
            href: facebookLink,
            imgSrc: `${imagePath}/${facebookIcon}`,
            imgAlt: 'Facebook logo',
            countriesToHide: ['ru'],
            visibleOnly: [],
            hideOn: ['ru'],
        },
        {
            href: instagramLink,
            imgSrc: `${imagePath}/${instagramIcon}`,
            imgAlt: 'Instagram logo',
            countriesToHide: ['ru'],
            visibleOnly: [],
            hideOn: ['ru'],
        },
        // {
        //     href: twitterLink,
        //     imgSrc: `${imagePath}/${twitterIcon}`,
        //     imgAlt: 'Twitter logo',
        //     countriesToHide: ['ru'],
        //     visibleOnly: [],
        //     hideOn: ['ru'],
        // },
        // {
        //     href: vkLink,
        //     imgSrc: `${imagePath}/${vkIcon}`,
        //     imgAlt: 'Vkontakte logo',
        //     countriesToHide: [],
        //     visibleOnly: ['ru'],
        //     hideOn: [],
        // },
    ];
};
